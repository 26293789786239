export default function IconDelete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 10"
      id="Delete-Bin-2--Streamline-Micro"
      width="100%"
      height="100%"
    >
      <desc>{'Delete Bin 2 Streamline Icon: https://streamlinehq.com'}</desc>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M0.75 2.504h8.5" strokeWidth={1} />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.25 9.5h-4.5a1 1 0 0 1 -1 -1v-6h6.5v6a1 1 0 0 1 -1 1Z"
        strokeWidth={1}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.208 2.5v-0.229a1.771 1.771 0 1 1 3.542 0V2.5"
        strokeWidth={1}
      />
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M3.989 4.5v3" strokeWidth={1} />
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M6.015 4.5v3" strokeWidth={1} />
    </svg>
  );
}
